<template>
  <div id="userLayout" class="user-layout-wrapper">
    <div class="container">
      <div class="user-layout-lang">
      </div>
      <div class="user-layout-content">
        <div class="top">
          <div class="header">
            <a href="/">
              <img src="~@/assets/logo1.png" class="logo" alt="logo" />
              <span class="title">展会报馆服务平台</span>
            </a>
          </div>
          <div class="desc">全球会展综合服务商</div>
        </div>

        <router-view />

        <div class="footer">
          <div class="links">
            <a href="javascript:;">帮助</a>
            <a href="javascript:;">隐私</a>
            <a href="javascript:;">条款</a>
          </div>
          <div class="copyright">Copyright &copy; 2022 展会报馆服务平台</div>
        </div>
      </div>
      <div class="cartoon">
        <ul class="square">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul class="circle">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLayout',
  components: {
  },
  mounted () {
    document.body.classList.add('userLayout')
  }
}
</script>

<style lang="less" scoped>

#userLayout.user-layout-wrapper {
  height: 100vh;
  background: linear-gradient(#114593, #ecac1b);
  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }
  .container {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;

    .user-layout-lang {
      width: 100%;
      height: 100px;
      line-height: 44px;
      text-align: right;

      .select-lang-trigger {
        cursor: pointer;
        padding: 12px;
        margin-right: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        vertical-align: middle;
      }
    }
    .user-layout-content {
      position: relative;
      z-index: 1;
      box-shadow: 0 0 6px #ccc;
      width: 500px;
      background-color: #fff;
      margin: auto;
      border-radius: 10px;
      padding: 32px 0 24px;
      .top {
        text-align: center;
        .header {
          height: 44px;
          line-height: 44px;
          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }
          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }
          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, 0.85);
            font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }
      .main {
        min-width: 260px;
        width: 368px;
        margin: 0 auto;
      }
      .footer {
        // position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 48px 0 24px;
        text-align: center;
        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
}
.cartoon{
  ul{
    li{
      position: absolute;
      border: 1px solid #fff;
      background-color: #fff;
      width: 30px;
      height: 30px;
      list-style: none;
      opacity: 0;
    }
  }
  .square{
    li{
      top: 40vh;
      left: 60vw;
      animation: square 10s linear infinite;
      &:nth-child(2){
        top: 80vh;
        left: 10vw;
        animation-delay: 2s;
      }
      &:nth-child(3){
        top: 80vh;
        left: 85vw;
        animation-delay: 4s;
      }
      &:nth-child(4){
        top: 10vh;
        left: 70vw;
        animation-delay: 6s;
      }
      &:nth-child(5){
        top: 10vh;
        left: 10vw;
        animation-delay: 8s;
      }
    }
  }
   @keyframes square {
    0%{
      transform: scale(0) rotateY(0deg);
      opacity: 1;
    }
    100%{
      transform: scale(5) rotateY(1000deg);
      opacity: 0;
    }
  }
  .circle{
    li{
      bottom: 0;
      left: 15vw;
      animation: circle 10s linear infinite;
      &:nth-child(2){
        left: 35vw;
        animation-delay: 2s;
      }
      &:nth-child(3){
        left: 55vw;
        animation-delay: 4s;
      }
      &:nth-child(4){
        left: 75vw;
        animation-delay: 6s;
      }
      &:nth-child(5){
        left: 90vw;
        animation-delay: 8s;
      }
    }
  }
  @keyframes circle {
    0%{
      transform: scale(0) rotateY(0deg);
      opacity: 1;
      bottom: 0;
      border-radius: 0;
    }
    100%{
      transform: scale(5) rotateY(1000deg);
      opacity: 0;
      bottom: 90vh;
      border-radius: 50%;
    }
  }
}
</style>
