import { defineStore } from 'pinia';
import { message } from 'ant-design-vue'
import { initAreaList, initFiles, initOptionsList, selectOrder, updateOrder, insertOrder } from '../../api'
const initInfo = [
  {
    key: 'pr',
    value: '项目名称：'
  },
  {
    key: 'pr',
    value: '展馆号：'
  },
  {
    key: 'pr',
    value: '展位号：'
  },
  {
    key: 'pr',
    value: '展区面积：'
  },
  {
    key: 'pr',
    value: '参展企业：'
  },
  {
    key: 'pr',
    value: '参展联系人：'
  },
  {
    key: 'pr',
    value: '参展负责人手机号：'
  },
  {
    key: 'pr',
    value: '搭建企业：'
  },
  {
    key: 'pr',
    value: '搭建商安全负责人：'
  },
  {
    key: 'pr',
    value: '搭建商负责人手机号：'
  }
]
const pavilion = defineStore('pavilion', {
  state() {
    return {

      initInfo,
      initDataUpdate: [], // 初始化数据上传列表（2） listProjectFile
      
      user: {}, // 用户数据
      project: {a:1}, // 项目数据
      
      reviseUserinfo: {}, // 修改基本信息 order
      reviseDataUpdate: {}, // 修改上传内容 fileList
      initTableList: {}, // 缴费明细表格（3） ListCost
      subtotal: {
        costs: 0, // 小计
        deposit: 0, // 押金
        overdue_money: 0, // 逾期费
        total: 0, // 合计
      }
  
    }
  },
  getters: {
    doubleCosts(state){
      let num = 0
      if (state.initTableList.data) {
        state.initTableList.data.forEach(item => {
          num += item.price * item.count
        });
      }
      state.subtotal.costs = num
      return num
    },
    doubleDeposit(state){
      let num = 0
      if (state.initTableList.data) {
        state.initTableList.data.forEach(item => {
          num += item.yj * item.count
        });
      }
      state.subtotal.deposit = num
      return num
    },
    doubleProportion(state){
      let num = 0
      if (state.project.overdue_proportion) {
        num = state.subtotal.costs*state.project.overdue_proportion/100
      }
      state.subtotal.overdue_money = num
      return num
    },
    doubleTotal(state){
      let num = 0
        num = state.subtotal.costs + state.subtotal.deposit + state.subtotal.overdue_money
      state.subtotal.total = num
      return num
    },
    // 报馆逾期时间判定 false 产生逾期费用 
    doubleOverdue(state){
      // 判断 逾期状态(获取当前时间跟截止时间对比（project.overdue)
      // --开启 -- 判断 新增---修改
      // ----新增 获取当前时间跟截止时间对比（project.etime）
      // ----修改 该条数据报馆时间（reviseUserinfo.create_time）跟截止时间对比（project.etime）
      if( !state.project.overdue ){
        // 未开启
        // console.log('未开启');
        return false
      }
      // console.log('开启');
      let cutoff = new Date(state.project.etime).getTime()
      
      let create_time = 0
      // 开启
      if ( state.reviseUserinfo.create_time) {
        // 修改
        // console.log('这是修改');
        create_time = state.reviseUserinfo.create_time
      } else {
        // 新增
        // console.log('这是新增');
        create_time =  Date.parse(new Date())
      }

      return cutoff <= create_time
    }
  },
  actions: {
    // 基本信息修改
    async resNewspaper ({ p_id, user_id, data_id, flag }) {
      this.initDataUpdate = []
      this.user = {}
      this.project = {}
      this.reviseUserinfo = {}
      this.reviseDataUpdate = {}
      this.initTableList = {}
      this.subtotal={
        costs: 0, // 小计
        deposit: 0, // 押金
        overdue_money: 0, // 逾期费
        total: 0, // 合计
      }
      // 传参 id, user_id
      console.log(user_id);
      const { user, project } = await initAreaList(p_id, user_id)
      this.user = user
      this.project = project
      // 传参 pid
      const initDataUpdate = await initFiles(p_id)

      this.initDataUpdate = acceptCupd(initDataUpdate)

      // 传参 pid
      const res = await initOptionsList(p_id)
      console.log(res);
      const initTableList = initArrManage(res.data)
      this.initTableList = initTableList
      
      if(flag) {
        // 当'flag'为'true'发送修改请求(selectOrder)
        // 传参 id pid number
        const { addList, costList, fileList, order, kpje, tkje } = await selectOrder(data_id, p_id)
        this.reviseDataUpdate = fileList
        this.reviseUserinfo = order
        const tableList = a(initTableList.arrChildren, costList )
        this.initTableList.data = tableList
      }
    },
    async resSendNewspaper() {
      const costArray = []
      const fileArray = []
      // 第三步数据处理
      this.initTableList.data.forEach(item=>{
        let { id, project_sort_name, des_id, des, price, count, yj } = item
        if (des_id) {
          costArray.push({
            id,
            name: project_sort_name,
            des_id,
            des,
            price,
            count,
            cost_money: count * price,
            yj,
            yj_money: yj * count
          })
        }else{
          costArray.push({
            id,
            name: project_sort_name,
            des,
            price,
            count,
            cost_money: count * price,
            yj,
            yj_money: yj * count
          })
        }
      })
      // 上传图片数据处理
      for (const key in this.reviseDataUpdate) {
        if (Object.hasOwnProperty.call(this.reviseDataUpdate, key)) {
          const element = this.reviseDataUpdate[key];
          element.forEach((item)=>{
            if (item.id) {
              fileArray.push({
                p_file_id: key,
                id: item.id,
                file_type: item.file_type,
                file_name: item.file_name,
                url: item.url
              })
            }else{
              fileArray.push({
                p_file_id: key,
                file_type: item.file_type,
                file_name: item.name,
                url: item.url
              })
            }
            
          })
        }
      }

      const obj = {
        id: this.reviseUserinfo.id,
        pid: this.reviseUserinfo.pid,
        aid: this.reviseUserinfo.aid,
        p_name: this.reviseUserinfo.p_name,
        booth_name: this.reviseUserinfo.booth_name,
        booth_area: this.reviseUserinfo.booth_area,
        corp: this.reviseUserinfo.corp,
        corp_contacts: this.reviseUserinfo.corp_contacts,
        corp_contacts_mobile: this.reviseUserinfo.corp_contacts_mobile,
        build_corp: this.reviseUserinfo.build_corp,
        build_contacts: this.reviseUserinfo.build_contacts,
        build_contacts_mobile: this.reviseUserinfo.build_contacts_mobile,
        examine: this.reviseUserinfo.examine,
        costs: this.subtotal.costs,
        deposit: this.subtotal.deposit,
        overdue_money: this.subtotal.overdue_money,
        total: this.subtotal.total,
        status: this.reviseUserinfo.status,
        costArray,
        fileArray
      }

      const id = this.reviseUserinfo.id
      let res = {}
      if (id) {
        // 修改
        obj.id = id * 1
        res = await updateOrder(obj)
      } else {
        // 新增
        obj.id = 0
        res = await insertOrder({ ...obj, user_id: userID })
      }

      message.success(res.msg)
      this.initDataUpdate = []
      this.user = {}
      this.project = {}
      this.reviseDataUpdate = {}
      this.initTableList = {}
      this.subtotal={
        costs: 0, // 小计
        deposit: 0, // 押金
        overdue_money: 0, // 逾期费
        total: 0, // 合计
      }
      window.sessionStorage.removeItem('id')
      window.sessionStorage.removeItem('project_id')
      console.log(this);
    }
  }
})

const initArrManage = (data)=>{
  let arrChildren = []
  for (var i = 0; i < data.length-1; i++) {
    for (var j = i+1; j < data.length; j++) {
      if (data[i].project_sort_name==data[j].project_sort_name) {
        let item = data.splice(j,1)
        if (data[i].children1) {
          data[i].children1.push(...item)
        } else {
          data[i].des_id = data[i].cost_id
          data[i].children1=[
            {...data[i]},
            ...item
          ]
        }
        j--;
      }
    }
  }
  data.forEach(item => {
    item.count = 0
    if (item.children1) {
      arrChildren.push({...item})
    }
  });
  return {data,arrChildren}
}
const a = (a, b)=>{
  if (b.length) {
    let list = [...b]
    let arr= []
    list.forEach(item => {
      let key = a.findIndex((citem,i)=> citem.children1.findIndex((kitem)=>kitem.cost_id == item.des_id)>=0 ?1:undefined)
      if (key == -1) {
        arr.push({...item})
      }else{
        let da = {...item,children1:[...a[key].children1]}
        da.des_id=da.des_id*1
        arr.push({...da})
      }
    });
    return arr
  }
}
const acceptCupd = (arr) =>{
  let newArr = []
  console.log(arr);
  arr.forEach((item)=>{
    let acceptStr = ''
    let itemArr = item.file_type.split('、')
    for (let index = 0; index < itemArr.length; index++) {
      const element = itemArr[index];
      acceptStr+=`.${element},`
    }
    console.log(acceptStr);
    item.accept = acceptStr
    newArr.push({...item})
  })
  return newArr
}
// const acceptCpud = computed(()=>{
//   let a = obj.acceptobj
//   if (!a) {
//     return ''
//   }
//   let arr = a.split('、')
//   console.log(a);
//   let acceptStr = ''
//   for (let index = 0; index < arr.length; index++) {
//     const element = arr[index];
//     acceptStr+=`.${element}`
//   }
//   return acceptStr
// })

export default pavilion
