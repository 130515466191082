import { service, serviceUrl } from './ajax.js'
// import axios from 'axios'
const ajax = service

export const logout = (data) => ajax.post('/logout', data)
export const autoLogin = (data) => ajax.post('/autoLogin', data)

// 上传
export const postUploadBackName = (data, url, type) => serviceUrl.post(`/Zzrd/${url}`, data)
// export const postUploadBackName = (data, url, type) => serviceUrl.post('/Zzrd/upload', data)

// 获取项目列表
export const initProjectList = (userId, pageNum, pageSize) => ajax('/Zzrd/project/listProject', { params: { pageNum, pageSize, user_id: userId } })

// 获取当前项目展区列表
export const initAreaList = (id, userId = null) => ajax('/Zzrd/project/selectProject', { params: { id, user_id: userId } })

// 报馆选项列表请求
export const initOptionsList = (pid) => ajax('/Zzrd/project/ListCost', { params: { pid } })

// 获取报馆上传资料列表
export const initFiles = (pid) => ajax('/Zzrd/project/listProjectFile', { params: { pid } })
// 我要报馆
export const insertOrder = (data) => ajax.post('/Zzrd/order/insertOrder', data)
// 修改报馆
export const updateOrder = (data) => ajax.post('/Zzrd/order/updateOrder', data)

// 修改/预览报馆查看
export const selectOrder = (id, pid, number = 0) => ajax('/Zzrd/order/selectOrder', { params: { id, pid, number } })

// 获取当前用户报馆的项目列表
export const listProjectNames = (userId) => ajax('/Zzrd/order/listProjectNames', { params: { user_id: userId } })

// 报馆列表 {}
export const listOrder = (data) => ajax.post('/Zzrd/order/listOrder', data)

// 删除项 {}
export const delOrder = (data) => ajax.post('/Zzrd/order/delOrder', data)

// 登录
export const login = (data) => ajax.post('/Zzrd/user/login', data)
// 注册/修改
export const register = (data) => ajax.post('/Zzrd/user/register', data)

// 获取短信验证码(查重)
export const regCode = (mb) => ajax('/Zzrd/send', { params: { mb } })
// 重置密码获取短信验证码(检测是否已注册)
export const regResetCode = (mb) => ajax('/Zzrd/sendCode', { params: { mb } })
// 重置密码
export const ResetPass = (data) => ajax.post('/Zzrd/user/checkSendCode', data)
// 获取基本信息
export const getUserInfo = (id) => ajax('/Zzrd/user/getUserInfo', { params: { id } })
// 更新基本信息-图片信息
export const insertPic = (data) => ajax.post('/Zzrd/user/insertPic', data)

// 发票
// 申请发票
export const operationInvoice = (data) => ajax.post('/Zzrd/invoice/operationInvoice', data)
// 开票列表
export const listInvoice = (data) => ajax.post('/Zzrd/invoice/listInvoice', data)
// 查看发票详情
export const findInvoice = (invoiceId) => ajax('/Zzrd/invoice/findInvoice', { params: { invoice_id: invoiceId } })
