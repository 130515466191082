import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import ProLayout, { PageContainer } from '@ant-design-vue/pro-layout'
import print from 'vue3-print-nb'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
// import store from './store'
// import PDF from './assets/htmlToPdf';
// import './mockjs'

import 'ant-design-vue/dist/antd.less'
import '@ant-design-vue/pro-layout/dist/style.css'
// app.config.globalProperties.$Pdf = PDF;

createApp(App).use(Antd).use(print).use(ProLayout).use(PageContainer).use(createPinia()).use(router).mount('#app')
