export const ACCESS_TOKEN = 'Access-Token'
export const USER_ID = 'user-id'
export const USER_STATUS = 'user-status'


export const TOGGLE_FIXED_HEADER = 'fixed_header'


export const AMEND_INIT_DATA_UPDATE = 'init_data_update'
export const AMEND_INIT_TABLE_LIST = 'init_table_list'
export const AMEND_RES_USER = 'res_user'
export const AMEND_RES_PROJECT = 'res_project'
export const AMEND_RES_TABLE_LIST = 'res_table_list'
export const AMEND_RES_FILE_LIST = 'res_file_list'
export const AMEND_RES_ORDER = 'res_order'
export const AMEND_SET_VALUE = 'set_value'

export const AMEND_RES_NEWSPAPER = 'res_newspaper'
