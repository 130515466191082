import {defineStore} from 'pinia';
import { message } from 'ant-design-vue'
import router from '../../router'
import { login, logout } from '../../api'
import { USER_ID, USER_STATUS } from '../mutation-types'

export default defineStore( 'user', {
  state: ()=>{
    return {
      token: '',
      name: '',
      welcome: '',
      avatar: '',
      roles: [],
      info: {},
      progress:false
    }
  },

  actions: {
    // 登录
    async Login ( userInfo) {
      const response = await login(userInfo)
      if (response.code*1 === 200) {
        // 获取用户基本信息存储到session中
        window.sessionStorage.setItem(USER_ID,response.id)
        window.sessionStorage.setItem(USER_STATUS,response.status)
        window.sessionStorage.setItem('user_name',response.user_name)
        router.push('/')
      } else{
        message.error(response.msg)
      }
    },

    // 登出
    async Logout () {
      window.sessionStorage.removeItem(USER_ID)
      window.sessionStorage.removeItem(USER_STATUS)
      router.push({ name: 'login' })
    }

  }
})

