import { createRouter, createWebHashHistory } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { BasicLayout, BlankLayout, UserLayout } from '../layouts/index'
import { USER_ID, USER_STATUS } from '../store/mutation-types'

const routes = [
  {
    path: '/',
    name: '/',
    component: BasicLayout,
    redirect: '/home',
    children: [
      {
        path: '/',
        name: 'home',
        meta:{ name: '网上报馆列表', belongs: 'home'},
        component: () => import('../views/home/home.vue')
      },
      {
        path: '/people',
        name: 'people',
        component: BlankLayout,
        redirect: '/people/edit',
        children: [
          {
            path: 'edit',
            name: 'edit',
            redirect: '/people/edit/basic',
            component: () => import('../views/people/edit.vue'),
            children: [
              {
                path: 'basic',
                name: 'basic',
                meta:{ name:'基本信息', belongs: 'edit' },
                component: () => import('../views/people/basic.vue'),
              },
              {
                path: 'certify',
                name: 'certify',
                meta:{ name:'认证设置', belongs: 'edit' },
                component: () => import('../views/people/certify.vue'),
              },
              {
                path: 'binding',
                name: 'binding',
                meta:{ name:'安全设置', belongs: 'edit' },
                component: () => import('../views/people/binding.vue'),
              },
              {
                path: 'notification',
                name: 'notification',
                meta:{ name:'新消息通知', belongs: 'edit' },
                component: () => import('../views/people/notification.vue'),
              }
            ]
          }
        ]
      },
      {
        path: '/pavilion',
        name: 'pavilion',
        component: BlankLayout,
        redirect: '/pavilion/newspaperList',
        children: [
          {
            path: 'newspaperlist',
            name: 'newspaperList',
            meta:{ name:'报馆管理', belongs: 'pavilion' },
            component: () => import('../views/pavilion/newspaperList.vue')
          },
          {
            path: 'newnewspaper',
            name: 'newNewspaper',
            meta:{ name:'我要报馆', belongs: 'home' },
            component: () => import('../views/pavilion/newNewspaper.vue')
          }
        ]
      },
      {
        path: '/invoice',
        name: 'invoice',
        component: BlankLayout,
        redirect: '/invoice/manage',
        children: [
          {
            path: 'manage',
            name: 'invoiceManage',
            meta:{ name:'索取发票', belongs: 'invoiceManage' },
            component: () => import('../views/invoiceSetUp/invoice.vue')
          },
          {
            path: 'record',
            name: 'invoiceRecord',
            meta:{ name:'开票记录', belongs: 'invoiceRecord' },
            component: () => import('../views/invoiceSetUp/record.vue')
          },
          {
            path: 'apply',
            name: 'invoiceApply',
            meta:{ name:'开票服务', belongs: 'invoice' },
            component: () => import('../views/invoiceSetUp/invoiceApply.vue')
          }
        ]
      },
      {
        path: 'ceshi',
        name: 'ceshi',
        meta:{ name:'我要报馆', belongs: 'home' },
        component: () => import('../views/ceshi/ceshi.vue')
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: UserLayout,
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/user/login.vue')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('../views/user/register.vue')
      },
      {
        path: 'recover',
        name: 'recover',
        component: () => import('../views/user/recover.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.name !== 'login' && !sessionStorage.getItem(USER_ID)) {
    if (to.name === 'register' || to.name === 'recover') {
      next()
    } else {
      next({ name: 'login' })
    }
  } else if ((to.name !== 'login' && to.name !== 'register' && to.name !== 'recover') && !(sessionStorage.getItem(USER_STATUS) * 1)) {
    if (to.path.indexOf('/people') != -1) {
      next()
    } else {
      Modal.warning({
        title: () => '温馨提醒',
        content: () => '请前往基本信息完善基本信息后方可报馆......',
        okText: () => '立即前往',
        onOk() {
          return new Promise((resolve, reject) => {
            resolve()
            router.push({ name: 'certify' })
          }).catch(() => console.log('Oops errors!'))
        }
      })
      next()
    }
  } else {
    next()
  }
})

export default router
