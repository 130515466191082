<template>
  <a-layout>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible >
      <div id="logo" class="ant-pro-sider-menu-logo" >
        <a href="/" class="router-link-active">
        <div>
          <img src="../assets/logo.png" alt="">
          <h1>展会报馆服务平台</h1>
        </div>
        </a>
      </div>
      <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys" @click="menuitem">
        <a-menu-item key="home">
          <home-outlined />
          <span>我要报馆</span>
        </a-menu-item>
        <a-menu-item key="edit">
          <user-outlined />
          <span>基本信息</span>
        </a-menu-item>
        <a-menu-item key="pavilion">
          <profile-outlined />
          <span>报馆管理</span>
        </a-menu-item>
        <a-sub-menu key="invoice">
          <template #title>
            <span>
              <upload-outlined />
              <span>发票管理</span>
            </span>
          </template>
          <a-menu-item key="invoiceManage">索取开票</a-menu-item>
          <a-menu-item key="invoiceRecord">开票记录</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="logout">
          <rollback-outlined />
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #008baf; padding:0px;height:auto">
        <div style="display:flex;justify-content:space-between;">
          <menu-unfold-outlined
            v-if="collapsed"
            class="trigger"
            @click="collapsed = false"
          />
          <menu-fold-outlined v-else class="trigger" @click="collapsed = true" />
          <div class="my-nav">
            <span class="user">
              <span class="user-img"><img src="https://picsum.photos/id/85/40/40" alt=""></span>
              <span>{{name}}</span>
              <div class="my-dropdown-open">
                <ul>
                  <li @click="menuitem({key:'edit'})"><user-outlined />&nbsp;&nbsp;基本信息</li>
                  <li @click="menuitem({key:'logout'})"><rollback-outlined />&nbsp;&nbsp;退出登录</li>
                </ul>
              </div>
            </span>
          </div>
        </div>
        <my-crumbs :name="selectedKeys"/>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#fff',position: 'relative'  }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { useUserStore } from '../store'
import {
  HomeOutlined,
  UserOutlined,
  WalletOutlined,
  ProfileOutlined,
  UploadOutlined,
  RollbackOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SmileOutlined
} from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue'
import { defineComponent, getCurrentInstance, onMounted, ref, watch, h } from 'vue'
import { initProjectList } from '../api'
export default defineComponent({
  name: 'BlankLayout',
  components: {
    HomeOutlined,
    UserOutlined,
    WalletOutlined,
    ProfileOutlined,
    UploadOutlined,
    RollbackOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const userStore = useUserStore()
    let previous = proxy.$root.$route.meta.belongs
    const selectedKeys = ref([previous])
    const name = ref('')
    const menuitem = (item) => {
      if (item.key !== previous) {
        if (item.key === 'logout') {
          userStore.Logout()
        } else {
          previous = item.key
          proxy.$root.$router.push({ name: item.key })
        }
      }
    }
    watch(
      () => proxy.$root.$route,
      val => {
        selectedKeys.value = [val.meta.belongs]
        previous = val.name
      }
    )
    const showConfirm = () => {
      Modal.warning({
        title: () => '温馨提醒',
        content: () => '请前往基本信息完善基本信息后方可报馆......',
        okText: () => '立即前往',
        onOk() {
          return new Promise((resolve, reject) => {
            resolve()
          }).catch(() => console.log('Oops errors!'))
        }
      })
    }
    const openNotification = async() => {
      let user_id = window.sessionStorage.getItem('user-id')
      const res = await initProjectList(user_id, 1, 12)
      let newTime = Date.now()
      let time = 0
      let arr = ''
      res.data.list.forEach(item => {
        time = Date.parse(item.etime)
        if (time - 604800000 <= newTime && time >= newTime) {
          arr += `${item.p_name}将于${item.etime}截止报名、`
        }
      })
      if (!arr.length) {
        return
      }
      arr = arr.substr(0, arr.length - 1)
      arr += '，逾期将产生额外费用！'
      notification.open({
        message: '温馨提示',
        description: arr,
        duration: 0,
        icon: h(SmileOutlined, {
          style: 'color: #108ee9'
        })
      })
    }
    onMounted(() => {
      name.value = window.sessionStorage.getItem('user_name')
      openNotification()
    })
    return {
      selectedKeys,
      collapsed: ref(false),
      menuitem,
      name
    }
  }
})
</script>
<style>
.ant-layout-header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}
#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout{
  min-height: 100vh !important;
}
.ant-pro-sider-menu-logo{
  position: relative;
  height: 64px;
  padding-left: 8px;
  overflow: hidden;
  -webkit-transition: all .3s;
  transition: all .3s;
  line-height: 64px;
  background: #001529;
}
.ant-pro-sider-menu-logo img{
  width: 44px;
}
.ant-pro-sider-menu-logo h1{
  color: #fff;
  font-size: 16px;
  margin: 0 0 0 8px;
  font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
  font-weight: 600;
  vertical-align: middle;
  display: inline-block;
}
</style>
<style lang="less" scoped>
.my-nav{
  display: flex;
  padding-right: 24px;
  .weather{
    padding: 0 12px;
  }
  .user{
    position: relative;
    padding: 0 12px;
    cursor: pointer;
    & > span{
      color: #fff;
    }
    .user-img{
      margin: 20px 0;
      margin-right: 8px;
      vertical-align: top;
      background: hsla(0,0%,100%,.85);
      width: 24px;
      height: 24px;
      line-height: 1;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    &:hover .my-dropdown-open{
      display: block;
    }
  }
  
  &>span:hover{
    background-color: rgba(0,0,0,.025);
  }
}
.my-dropdown-open{
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 9;
  width: auto;
  transition: all 1s;
  padding: 5px;
  display: none;
  ul{
    list-style: none;
    padding: 4px 0;
    background-color: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    margin: 0;
    li{
      white-space: nowrap;
      min-width: 160px;
      transition: all .3s;
      cursor: pointer;
      line-height: 22px;
      color: rgba(0,0,0,.65);
      padding: 5px 12px;
      clear: both;
      &:hover{
        background-color:#e6f7ff
      }
    }
  }
}
</style>
