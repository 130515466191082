<template>
  <div class="progress">
    <a-progress :percent="percent" status="active" size="small" :strokeWidth="4" v-show="previousFlag" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useUserStore } from '../../store'
export default {
  setup () {
    const userStore = useUserStore()
    let percent = ref(0)
    let previousFlag = ref(false)
    let timer
    let progress = computed(() => userStore.progress)
    watch(progress, (newValue) => {
      if (newValue && percent.value <= 90) {
        previousFlag.value = true
        timer = setInterval(() => {
          percent.value = percent.value + 1
        },30)
      } else {
        clearInterval(timer)
        percent.value = 100
        setTimeout(() => {
          previousFlag.value = false
          percent.value = 0
        }, 300)
      }
    })
    return {
      previousFlag,
      percent
    }
  }
}
</script>

<style scoped lang='less'>
  .progress{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 4px;
    z-index: 99999;
  }
  .progress .ant-progress{
    font-size: 0;
    vertical-align: top;
  }
</style>
