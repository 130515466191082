import axios from 'axios'
// import qs from 'qs'
// import { Message } from 'element-ui'
// import Vue from 'vue'
// import store from '../store'
import { useUserStore } from '../store'

export const service = axios.create({
  // headers: {'content-type': 'application/x-www-form-urlencoded'},
  // headers: {'content-type': 'application/json'},
  // baseURL: '/api',
  timeout: 20000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    // 可以添加请求头 token userid 。。。
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    // config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Content-Type'] = 'application/json; charset=UTF-8'
    config.transformRequest = [function (data) {
      // console.log(data)
      // if (data&&data.flag == 1) {
      //    return data.data
      // }
      data = JSON.stringify(data); return data
      // data = qs.stringify(data, { indices: false })
      // data = qs.stringify(data,{arrayFormat: 'indices'});
      // return data
    }]
    // store.commit('SET_PROGRESS', true)
    useUserStore().progress = true
    return config
  },
  error => {
    // tryHideFullScreenLoading();
    // this.$message.error(error);
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    // 响应状态不是200时
    if (response.status !== 200) {
      // 如果token过期或未登录 重定向至登录页面
      if (res.code === 201) {
        // Message({
        //   message: '登录过期，请重新登录',
        //   type: 'error',
        //   duration: 2000
        // })
        // 去登录
      } else if (res.code === 201) {
        // Message({
        //   message: '请登录',
        //   type: 'error',
        //   duration: 2000
        // })
        // 去登录
      } else {
        // console.log('其他错误');
        // Message({
        //   message: response.message,
        //   type: 'error',
        //   duration: 2000
        // })
      }
      // store.commit('SET_PROGRESS', false)
      useUserStore().progress = false
      return Promise.reject(new Error('error'))
    } else {
      // store.commit('SET_PROGRESS', false)
      useUserStore().progress = false
      return response.data
    }
  },
  error => {
    // Message({
    //   message: '网络接连失败，请检查网络',
    //   type: 'error',
    //   duration: 2000
    // })
    // store.commit('SET_PROGRESS', false)
    useUserStore().progress = false
    return Promise.reject(error)
  }
)

export const serviceUrl = axios.create({
  // baseURL: '/api',
  timeout: 20000 // 请求超时时间
})

serviceUrl.interceptors.request.use(
  config => {
    config.transformRequest = [function (data) {
      // console.log(data)
      return data
    }]
    // store.commit('SET_PROGRESS', true)
    useUserStore().progress = true
    return config
  },
  error => {
    Promise.reject(error)
  }
)

serviceUrl.interceptors.response.use(
  response => {
    // const res = response.data
    // 响应状态不是200时
    if (response.status !== 200) {
      // store.commit('SET_PROGRESS', false)
      useUserStore().progress = false
      return Promise.reject(new Error('error'))
    } else {
      // store.commit('SET_PROGRESS', false)
      useUserStore().progress = false
      return response.data
    }
  },
  error => {
    // store.commit('SET_PROGRESS', false)
    useUserStore().progress = false
    return Promise.reject(error)
  }
)

// export default service
