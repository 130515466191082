<template>
  <a-config-provider :locale="locale">
    <Progress />
    <router-view/>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Progress from './components/smallParts/progress.vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  name: 'App',
  components: {
    Progress
  },
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>
<style>
.state-orange::before,
.state-green::before,
.state-red::before{
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: -3px;
  vertical-align: middle;
}
.state-orange::before{
  background-color: orange;
}
.state-red::before{
  background-color: red;
}
.state-green::before{
  background-color: green;
}
.price-logo::before{
  content: '\ffe5';
  display: inline-block;
  margin-right: 2px;
}
.ant-image-preview-img{
  background-color: #fff;
}

.kele-ellipsis-2{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical
}
</style>
